import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';

function Footer() {

  return (
    <div>
      <section className="footer-top-area ptb-100-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6 col-md-6 col-6" id="footer-logo">
              <div className="single-widget">
                <Link className="logo" to="/">
                  <img src="./img/foundation_logo.png" alt="Masson Foundation" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6 col-12">
              <div className="single-widget iccy">
                <h3 className="ic"><h3>Infinite Curiosity</h3><span className="AppleChancery cy">Creative Youth</span></h3>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-widget">
                <ul className="social-icon">
                  <li className="connect">
                    Connect
                  </li>
                  <li>
                    <a href="#" className="social-icons">
                      <FontAwesomeIcon className="icons" icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="social-icons">
                      <FontAwesomeIcon className="icons" icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="social-icons">
                      <FontAwesomeIcon className="icons" icon={faLinkedinIn} />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="social-icons">
                      <FontAwesomeIcon className="icons" icon={faInstagram} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="">

            <h2>Newsletter</h2>
            <div className="widget subscribe">
              <form className="newsletter-form subscribe-form" data-toggle="validator">
                <input type="email" className="input-newsletter subscribe-field" placeholder="Enter email address" name="EMAIL" required autocomplete="off" />

                <button type="submit" className="search-submit default-btn subscribe-submit">Sbuscribe</button>
                <div id="validator-newsletter" className="form-result"></div>
              </form>
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-2 col-sm-12 col-md-12 col-12 center-content">
              <div className="single-widget">
                <ul>
                  <li>
                    <a href="#">About Us</a>
                  </li>
                  <li>
                    <a href="#">Our Approach</a>
                  </li>
                  <li>
                    <a href="#">Our Leadership</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-sm-12 col-md-12 col-12 center-content">
              <div className="single-widget">
                <ul>
                  <li>
                    <a href="#">Our Initatives</a>
                  </li>
                  <li>
                    <a href="#">Early Learning</a>
                  </li>
                  <li>
                    <a href="#">Adolsecent Learning</a>
                  </li>
                  <li>
                    <a href="#">Science</a>
                  </li>
                  <li>
                    <a href="#">Computer Science</a>
                  </li>
                  <li>
                    <a href="#">Education</a>
                  </li>
                  <li>
                    <a href="#">Overview</a>
                  </li>
                  <li>
                    <a href="#">Technology</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-sm-12 col-md-12 col-12 center-content">
              <div className="single-widget">
                <ul>
                  <li>
                    <a href="#">News &amp; Stories</a>
                  </li>
                  <li>
                    <a href="#">Latest News</a>
                  </li>
                  <li>
                    <a href="#">Stories</a>
                  </li>
                  <li>
                    <a href="#">Meida Gallery</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-sm-12 col-md-12 col-12 center-content">
              <div className="single-widget">
                <ul>
                  <li>
                    <a href="#">Careers</a>
                  </li>
                  <li>
                    <Link to="/donate">Overview</Link>
                  </li>
                  <li>
                    <Link to="/donate">Donate</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-6 offset-25 mt-md-2 mt-sm-2">
              <div className="single-widget">
                <p className="mail-txt">Receive Updates From The Masson Foundation</p>
                <div className="widget subscribe">
                  <form className="newsletter-form subscribe-form" data-toggle="validator">
                    <input type="email" className="input-newsletter subscribe-field" placeholder="Enter email address" name="EMAIL" required autocomplete="off" />
                    <button type="submit" className="search-submit default-btn subscribe-submit">SIGN UP <span><img className="signup-icon" alt="sign up" src="./img/icons/signup.png" /></span></button>
                    <div id="validator-newsletter" className="form-result"></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer-bottom-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div>
                <ul className="terms-conditions">
                  <li><a href="#">Fraud Alert</a></li>
                  <li><a href="#">Privacy Policy</a></li>
                  <li><a href="#">Cookie Notice</a></li>
                  <li><a href="#">Terms of Use</a></li>
                  <li><a href="#">Brand Guidelines</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <p>
                Masson Foundation is a not-for-Profit Organization
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer;