import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes, faAngleDown, faBars } from '@fortawesome/free-solid-svg-icons';



import React, { useState, useEffect } from 'react';

function Navbar(topIcon) {
  const [searchBox, showSearchBox] = useState(false);
  const [searchStyle, setSearchStyle] = useState({ 'display': 'none' });
  const [isActive, setActive] = useState(false);
  const [isNavHeight, setNavHeight] = useState();

  useEffect(() => {
    if (searchBox) {
      setSearchStyle({ 'display': 'block' })
    } else {
      setSearchStyle({})
    }
  }, [searchBox])

  /* useEffect(()=>{
    setNavHeight(window.innerHeight)
  },[!isActive]) */

  const showSearch = () => {
    showSearchBox(true)
  }

  const closeSearch = () => {
    showSearchBox(false)
  }

  const toggleClass = () => {
    setActive(!isActive);
    !isActive ? setNavHeight(window.innerHeight) : setNavHeight(0)
  };

  return (
    <div className="nivo-header-style-one fixed-top">
      {/* <div className="header-wrap">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <ul className="header-information ">
                <li>
                  <a href="tel:+(321)-948-754">
                    <i className="fa fa-phone"></i>
                    +(321) 948 754
                  </a>
                </li>
                <li>
                  <a href="mailto:hello@nivo.com">
                    <i className="fa fa-envelope"></i>
                    hello@nivo.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-6">
              <ul className="social-icon">
                <li>
                  Follow Us:
                </li>
                <li>
                  <a href="#">
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
      <div className={`navbar-area ${topIcon ? 'is-sticky' : ''}`}>
        <div className="mobile-nav mean-container">
          <div className="mean-bar">
            {!isActive && <div className='nav-div' onClick={toggleClass} >
              <svg className='bar-icons' width="23" height="20" xmlns="http://www.w3.org/2000/svg">
                <g fillRule="nonzero" stroke="#fff" strokeWidth="3.5" fill="none" strokeLinecap="square">
                  <path d="M22 .886H1.004M22 9.582H1.004M22 18.277H1.004" />
                </g>
              </svg>
            </div>}
            {isActive && <div className='close-div' onClick={toggleClass} ><span>X
            </span></div>}
            <nav className="mean-nav" style={{ height: isNavHeight }}>
              <ul className={`navbar-nav m-auto ${isActive ? 'd-block' : 'd-none'}`}>
                <li className="nav-item mob-font">
                  <a href="about.html" className="nav-link">About Us</a>
                </li>
                <li className="nav-item mob-font">
                  <a href="#" className="nav-link">Our Initiatives</a>
                  <ul className="dropdown-menu" >
                    <li className="nav-item">
                      <a href="event-grid.html" className="nav-link">Event Grid</a>
                    </li>
                    <li className="nav-item">
                      <a href="event-list.html" className="nav-link">Event List</a>
                    </li>
                    <li className="nav-item">
                      <a href="event-details.html" className="nav-link">Event Details</a>
                    </li>
                  </ul>
                  <svg className="expand-icon" height="9" viewBox="0 0 14 9" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m-3 3 6 6 6-6" fill="none" stroke="#ff414b" strokeWidth="2" transform="matrix(-1 0 0 1 10 -2)" /></svg>
                </li>
                <li className="nav-item mob-font">
                  <a href="#" className="nav-link">News &amp; Stories</a>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <a href="project.html" className="nav-link">Project</a>
                    </li>
                    <li className="nav-item">
                      <a href="project-details.html" className="nav-link">Project Details</a>
                    </li>
                  </ul>
                  <svg className="expand-icon" height="9" viewBox="0 0 14 9" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m-3 3 6 6 6-6" fill="none" stroke="#ff414b" strokeWidth="2" transform="matrix(-1 0 0 1 10 -2)" /></svg>
                </li>
                <li className="nav-item mob-font">
                  <a href="#" className="nav-link dropdown-toggle">News &amp; Stories</a>
                  <ul className="dropdown-menu" >
                    <li className="nav-item">
                      <a href="causes-grid.html" className="nav-link">Causes Grid</a>
                    </li>
                    <li className="nav-item">
                      <a href="causes-details.html" className="nav-link">Causes Details</a>
                    </li>
                  </ul>
                  <svg className="expand-icon" height="9" viewBox="0 0 14 9" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m-3 3 6 6 6-6" fill="none" stroke="#ff414b" strokeWidth="2" transform="matrix(-1 0 0 1 10 -2)" /></svg>
                </li>
                <li className="nav-item mean-last mob-font">
                  <a href="contact.html" className="nav-link">Careers</a>
                </li>
                <li className="nav-item mean-last mob-font">
                  <a href="contact.html" className="nav-link">Contact</a>
                </li>
              </ul>
              <div className="others-option">
                <div className="others-options">
                  <div className="option-item">
                    <i className="search-btn fa fa-search"></i>
                    <i className="close-btn fa fa-close"></i>
                    <div className="search-overlay search-popup">
                      <div className="search-box">
                        <form className="search-form">
                          <input className="search-input" name="search" placeholder="Search" type="text" />
                          <button className="search-button" type="submit"><i className="fa fa-search"></i></button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="donate.html" className="donate">Donate Now</a>
              </div>
            </nav>
          </div>
          <a href="index.html" className="logo">
            <img src="./img/foundation_logo.png" alt="Nivo Logo" />
          </a>
        </div>
        <div className="nivo-nav-one">
          <div className="main-nav">
            <nav className="navbar navbar-expand-md navbar-light">
              <div className="container">
                <div className="">
                  <Link className="navbar-brand" to="/" className="logo">
                    <img src="./img/foundation_logo.png" alt="Nivo Logo1" />
                  </Link>

                  <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul className="navbar-nav ">
                      <li className="nav-item">
                        <Link to="/">About Us</Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/about" className="nav-link">Approach</Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/ourwork" className="nav-link">Programs</Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/blog" className="nav-link">News &amp; Stories</Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/joinus" className="nav-link">Careers</Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/joinus" className="nav-link">Contact</Link>
                      </li>
                      <li className="nav-item">
                        <div className="others-option">
                          <div className="others-options">
                            <div className="option-item">
                              {!searchBox &&
                                <span><img className="search-icon" src="./img/icons/search.png" alt="search" onClick={() => { showSearch() }} /></span>
                                // <FontAwesomeIcon icon={faSearch} onClick={() => { showSearch() }} />
                              }
                              {searchBox &&
                                <FontAwesomeIcon icon={faTimes} onClick={() => { closeSearch() }} />
                              }
                              <i className="close-btn fa fa-close"></i>
                              <div className="search-overlay search-popup" style={searchStyle}>
                                <div className='search-box'>
                                  <form className="search-form">
                                    <input className="search-input" name="search" placeholder="Search" type="text" />
                                    <button className="search-button" type="submit"><i className="fa fa-search"></i></button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="nav-item language-nav">
                        <a href="#" className="nav-link">Language</a>
                        <ul className="dropdown-menu">
                          <li className="nav-item">
                            <a href="project.html" className="nav-link">Project</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar;