function Contact() {
  return (
    <div>
      <div className="page-title-area item-bg-7">
        <div className="container">
          <div className="page-title-content">
            <h2>Contact</h2>
            <ul>
              <li>
                <a href="index.html">
                  Home
                  <i className="fa fa-chevron-right"></i>
                </a>
              </li>
              <li>Contact</li>
            </ul>
          </div>
        </div>
      </div>
      <section className="contact-box ptb-100-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-contact-box">
                <i className="fa fa-map-marker"></i>
                <div className="contect-title">
                  <h3>Address</h3>
                  <p>660 Brooklyn street, New <br /> York street</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-contact-box">
                <i className="fa fa-envelope"></i>
                <div className="contect-title">
                  <h3>Email</h3>
                  <a href="mailto:hello@nivo.com">hello@nivo.com</a>
                  <a href="mailto:info@nivo.com">info@nivo.com</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
              <div className="single-contact-box">
                <i className="fa fa-phone"></i>
                <div className="contect-title">
                  <h3>Phone</h3>
                  <a href="tel:+44-587-154756">+44 587 154756</a>
                  <a href="tel:+44-587-154756">+55 5555 14574</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-area pb-100">
        <div className="container">
          <div className="section-title">
            <h2>Drop us message for any query</h2>
            <p>If you have an idea, we would love to hear about it.</p>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="contact-form">
                <form id="contactForm" novalidate="true">
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <div className="form-group">
                        <input type="text" name="name" id="name" className="form-control" required="" data-error="Please enter your name" placeholder="Your Name"/>
                        <div className ="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-sm-6">
                      <div className="form-group">
                        <input type="email" name="email" id="email" className="form-control" required="" data-error="Please enter your email" placeholder="Your Email"/>
                        <div className ="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-sm-6">
                      <div className="form-group">
                        <input type="text" name="phone_number" id="phone_number" required="" data-error="Please enter your number" className="form-control" placeholder="Your Phone"/>
                        <div className ="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-sm-6">
                      <div className="form-group">
                        <input type="text" name="msg_subject" id="msg_subject" className="form-control" required="" data-error="Please enter your subject" placeholder="Subject"/>
                        <div className ="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group">
                        <textarea name="message" className="form-control" id="message" cols="30" rows="8" required="" data-error="Write your message" placeholder="Your Message"></textarea>
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <button type="submit" className="default-btn disabled">
                        <span>Send Message</span>
                      </button>
                      <div id="msgSubmit" className="h3 text-center hidden"></div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="map-area">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96873.5474759874!2d-74.01503722010817!3d40.645353094792064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24416947c2109%3A0x82765c7404007886!2sBrooklyn%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1612887942866!5m2!1sen!2sbd"></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Contact;