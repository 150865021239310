import axios from 'axios';

export const getHomeList = () => {
    return axios.post('http://api.massonfoundation.org/api/v1/home')
}

export const getArticleDetails = (payload) => {
    return axios.post('http://api.massonfoundation.org/api/v1/article', payload)
}

export const getArticleList = (payload) => {
    return axios.post('http://api.massonfoundation.org/api/v1/articles', payload)
}