function Joinus() {
  return (
    <div>
      <section className="banner-area">
        <div className="container">
          <div className="banner-wrap">
            <div className="row align-items-center m-0">
              Joinus Works!
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Joinus;