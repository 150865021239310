function About() {
  return (
    <div>
      <div className="page-title-area item-bg-1">
        <div className="container">
          <div className="page-title-content">
            <h2>About</h2>
            <ul>
              <li>
                <a href="index.html">
                  Home
                  <i className="fa fa-chevron-right"></i>
                </a>
              </li>
              <li>About</li>
            </ul>
          </div>
        </div>
      </div>
      <section className="about-area abouts-areas ptb-100 two">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img">
                <img src="assets/img/abouts-2.jpg" alt="About" />
                <div className="videos-wraps">
                  <div className="video-wrap">
                    <a href="play-video" className="video-btn" data-ilb2-video="{&quot;controls&quot;:&quot;controls&quot;, &quot;autoplay&quot;:false, &quot;sources&quot;:[{&quot;src&quot;:&quot;assets/img/charity.mp4&quot;, &quot;type&quot;:&quot;video/mp4&quot;}]}" data-imagelightbox="video">
                      <i className="flaticon-play-button"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-text">
                <span>About Us</span>
                <h2>A Dream in their Mind is Our Mission</h2>
                <p className="mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy.</p>
                <p className="mb-0">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Excepturi ullam quibusdam, maiores a explicabo magnam dolor? Architecto velit, assumenda dolore consectetur adipisicing elit explicabo magnam dolor? Architecto velit, assumenda dolore magnam dolor? Architecto Lorem ipsum, dolor sit amet consectetur Architecto</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="help-area two">
        <div className="container">
          <div className="help-title">
            <h2>Some Good Causes</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.</p>
            <a className="default-btn" href="#">Donate Now</a>
            <a className="default-btn join" href="#">Join Now</a>
          </div>
        </div>
      </section>
      <section className="get-started-today-area pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="get-started-img">
                <img src="assets/img/get-started/get-started.png" alt="Donation" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="get-started-title">
                <span>Get Started Today</span>
                <h2>Our Fundraise Plans</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </div>
              <div className="get-started-list">
                <img src="assets/img/get-started/1.png" alt="Started" />
                <h3>Education For Children</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
              </div>
              <div className="get-started-list">
                <img src="assets/img/get-started/2.png" alt="Started" />
                <h3>Home For Homeless People</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
              </div>
              <div className="get-started-list">
                <img src="assets/img/get-started/3.png" alt="Started" />
                <h3>Free Medical Services</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
              </div>
              <a className="default-btn" href="#">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="organaization-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="organaization-text">
                <h2>Small Growing charity organaization wants to raise money</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis  suspendisse  gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simple dummy text of the printing and typesetting industry.</p>
                <a className="default-btn" href="#">
                  How Donate
                </a>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="organaization">
                <img src="assets/img/organaization-2.jpg" alt="Organaization" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-team-area pb-70">
        <div className="container">
          <div className="section-title">
            <span>Team</span>
            <h2>Our Volunteers</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.</p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-team">
                <img src="assets/img/team/1.jpg" alt="Team"/>
                <div className ="team-text">
                <h3>Jeniya Jemmy</h3>
                <p>Reporter</p>
                <ul>
                <li>
                <a href="#">
                <i className ="fa fa-facebook"></i>
                </a>
                </li>
                <li>
                <a href="#">
                <i className ="fa fa-twitter"></i>
                </a>
                </li>
                <li>
                <a href="#">
                <i className ="fa fa-linkedin"></i>
                </a>
                </li>
                </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-team">
                <img src="assets/img/team/2.jpg" alt="Team"/>
                <div className ="team-text">
                <h3>Debit Denish</h3>
                <p>Photographer</p>
                <ul>
                <li>
                <a href="#">
                <i className ="fa fa-facebook"></i>
                </a>
                </li>
                <li>
                <a href="#">
                <i className ="fa fa-twitter"></i>
                </a>
                </li>
                <li>
                <a href="#">
                <i className ="fa fa-linkedin"></i>
                </a>
                </li>
                </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6 offset-sm-3 offset-lg-0">
              <div className="single-team">
                <img src="assets/img/team/3.jpg" alt="Team"/>
                <div className ="team-text">
                <h3>Alen Jems</h3>
                <p>Engineer</p>
                <ul>
                <li>
                <a href="#">
                <i className ="fa fa-facebook"></i>
                </a>
                </li>
                <li>
                <a href="#">
                <i className ="fa fa-twitter"></i>
                </a>
                </li>
                <li>
                <a href="#">
                <i className ="fa fa-linkedin"></i>
                </a>
                </li>
                </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default About;