import axios from 'axios';
import { useParams } from 'react-router';
import React, { useState, useEffect } from 'react';
import { getArticleDetails } from '../service/services';

function Story() {
  const { id, title } = useParams();
  const [articleDetails1, articleDetails2] = useState([]);
  let [isLoaded, setIsLoaded] = useState(false);
  console.log(id, title)

  useEffect(() => {
    articleDetails();
  }, []);

  const articleDetails = () => {
    getArticleDetails({ 'id': id }).then((res) => {
      //console.log(res.data.article.meta_desc)
      articleDetails2(res.data['article'].content);
      setIsLoaded(true)
    }).catch((error) => {
      console.log(error)
    });
  }

  return (
    <div>
      <section className="banner-area">
        <div className="container">
          <div className="banner-wrap">
            {isLoaded ?
              <div className="row align-items-center m-0" dangerouslySetInnerHTML={{ __html: articleDetails1 }}>
              </div>
              :
              <div>Loading Article</div>
            }
          </div>
        </div>
      </section>
    </div>
  )
}

export default Story;