import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { getHomeList, getArticleList } from '../service/services';
import React, { useState, useEffect } from 'react';

function Home() {
  const [getHomeDetails, setHomeDetails] = useState([]);
  const [getArticlesList, setArticlesList] = useState([]);
  let [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getHome();
  }, []);

  const getHome = () => {
    getHomeList().then((res) => {
      setHomeDetails(res.data)
      setIsLoaded(true)
    });
    getArticle();
  }

  const getArticle = () => {
    getArticleList({ 'id': 1, type: 'latest' }).then((res) => {
      console.log(res)
      setArticlesList(res.data)
    })
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 2000,
    autoplaySpeed: 5000,
    cssEase: "linear"
  };
  const settingsThoughts = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear"
  };

  const settingsClients = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 3,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear"
  };


  return (
    <div className="home-main">
      <section className="slider-section">
        <div className="row">
          <div className="col-12">
            {isLoaded ?
              <Slider {...settings}>
                {
                  getHomeDetails.slider.map((item, idx) => {
                    return (
                      <div className="">
                        <div style={{
                          backgroundImage: "url(" + "https://cdn.reportcrux.com/img/" + item.image_url + ")",
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          width: '100%',
                          height: '100vh'
                        }}>
                          <div class="banner-info slide-content">
                            <div class="inner">
                              <div class="cov-lft-bord">
                                <h2>Infinite Curosity<br /> Creative Youth</h2>
                                <p class="para-desc">Leveraging technology, community-driven solutions and collaboration to build a more inclusive, just, and healthy future.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="row align-items-center m-0">
                        <div className="col-lg-6 p-0">
                          <div className="banner-text">
                            <span>Bringing Completeness2</span>
                            <h1>To teach is to touch a life forever!</h1>
                            <p>{item.description}.</p>
                          </div>
                        </div>
                        <div className="col-lg-6 p-0">
                          <div className="banner-img">
                            <img src={`https://cdn.reportcrux.com/img/${item.image_url}`} alt="banner" />
                          </div>
                        </div>
                      </div> */}
                      </div>
                    )
                  })
                }
              </Slider>
              : <div>is loading</div>}
          </div>
        </div>
        {/* <div className=" shape shape-1">
          <img src="assets/img/shape/1.png" alt="Shape" />
        </div>
        <div className=" shape shape-2">
          <img src="assets/img/shape/2.png" alt="Shape" />
        </div>
        <div className=" shape shape-3">
          <img src="assets/img/shape/3.png" alt="Shape" />
        </div>
        <div className=" shape shape-4">
          <img src="assets/img/shape/4.png" alt="Shape" />
        </div>
        <div className=" shape shape-5">
          <img src="assets/img/shape/5.png" alt="Shape" />
        </div> */}
      </section>
      <section id="central-focus" className="good-causes-area ptb-100-70">
        <div className="container">
          <div className="section-title">
            {/* <span>Our Central Focus</span> */}
            <h2>Our Focus</h2>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
              magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.</p> */}
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-good-causes">
                <img src="./img/news/news3.png" />
                <h3>Early Learning</h3>
                <p>Lorem ipsum dolor amet, consectetur amet adipiscing elit, sed do eiusmod incididunt labore dolore
                  consectetur amet adipiscing elit.</p>
                <a className="read-more" href="causes-details.html">
                  Read More
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-good-causes">
                <img src="./img/news/news5.png" />
                <h3>Adolescent Learning</h3>
                <p>Lorem ipsum dolor amet, consectetur amet adipiscing elit, sed do eiusmod incididunt labore dolore
                  consectetur amet adipiscing elit.</p>
                <a className="read-more" href="causes-details.html">
                  Read More
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-good-causes">
                <img src="./img/news/news2.png" />
                <h3>Science</h3>
                <p>Lorem ipsum dolor amet, consectetur amet adipiscing elit, sed do eiusmod incididunt labore dolore
                  consectetur amet adipiscing elit.</p>
                <a className="read-more" href="causes-details.html">
                  Read More
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-good-causes">
                <img src="./img/news/news1.png" />
                <h3>Computer Science</h3>
                <p>Lorem ipsum dolor amet, consectetur amet adipiscing elit, sed do eiusmod incididunt labore dolore
                  consectetur amet adipiscing elit.</p>
                <a className="read-more" href="causes-details.html">
                  Read More
                </a>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6 position-relative">
              <div className="single-good-causes">
                <img src="./img/central-focus1.jpg" />
              </div>
              <div className="text">
                <img src="./img/news/news1.png" />
                <h3>Computer Science</h3>
                <p>Lorem ipsum dolor amet, consectetur amet adipiscing elit, sed do eiusmod incididunt labore dolore
                  consectetur amet adipiscing elit.</p>
                <a className="read-more" href="causes-details.html">
                  Read More
                </a>
              </div>
            </div> */}
          </div>
        </div>
        {/* <div className=" shape shape-1">
          <img src="assets/img/shape/1.png" alt="Shape" />
        </div>
        <div className=" shape shape-2">
          <img src="assets/img/shape/2.png" alt="Shape" />
        </div>
        <div className=" shape shape-3">
          <img src="assets/img/shape/3.png" alt="Shape" />
        </div>
        <div className=" shape shape-5">
          <img src="assets/img/shape/5.png" alt="Shape" />
        </div> */}
      </section >
      {/* <section className="about-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img">
                <img src="assets/img/about-img.jpg" alt="About" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-text ptb-100">
                <span>About Us</span>
                <h2>A Dream in their Mind is Our Mission</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply.</p>
                <ul>
                  <li>
                    <i className="flaticon-check-mark"></i>
                    Become Volunteer
                  </li>
                  <li>
                    <i className="flaticon-check-mark"></i>
                    Quick  Fundraise
                  </li>
                  <li>
                    <i className="flaticon-check-mark"></i>
                    Give Donation
                  </li>
                  <li>
                    <i className="flaticon-check-mark"></i>
                    Join Event
                  </li>
                </ul>
                <a className="default-btn" href="about.html">More Details</a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="active-campaing-area pt-100">
        <div className="container">
          <div className="section-title">
            <span>Active Campaign</span>
            <h2>Some Good Causes</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.</p>
          </div>
          <div className="campaing-wrap">
            <Slider {...settings}>
              <div>
                <div className="single-campaing">
                  <div className="campaing-img">
                    <img src="assets/img/campaing/1.jpg" alt="" />
                  </div>
                  <div className="campaing-text">
                    <div className="progress pink">
                      <div className="progress-bar">

                      </div>
                    </div>
                    <ul>
                      <li><span>Raised:</span> $5000.00</li>
                      <li className="left-site"><span>Goal:</span> $8000.00</li>
                    </ul>
                    <h3>Help Us To Send Food</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur scinelit, sed do eiusmod tempor incididunt</p>
                    <a className="read-more" href="causes-details.html">Read More</a>
                  </div>
                </div>
              </div>
              <div>
                <div className="single-campaing">
                  <div className="campaing-img">
                    <img src="assets/img/campaing/1.jpg" alt="" />
                  </div>
                  <div className="campaing-text">
                    <div className="progress pink">
                      <div className="progress-bar">

                      </div>
                    </div>
                    <ul>
                      <li><span>Raised:</span> $5000.00</li>
                      <li className="left-site"><span>Goal:</span> $8000.00</li>
                    </ul>
                    <h3>Help Us To Send Food</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur scinelit, sed do eiusmod tempor incididunt</p>
                    <a className="read-more" href="causes-details.html">Read More</a>
                  </div>
                </div>
              </div>
              <div>
                <div className="single-campaing">
                  <div className="campaing-img">
                    <img src="assets/img/campaing/1.jpg" alt="" />
                  </div>
                  <div className="campaing-text">
                    <div className="progress pink">
                      <div className="progress-bar">

                      </div>
                    </div>
                    <ul>
                      <li><span>Raised:</span> $5000.00</li>
                      <li className="left-site"><span>Goal:</span> $8000.00</li>
                    </ul>
                    <h3>Help Us To Send Food</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur scinelit, sed do eiusmod tempor incididunt</p>
                    <a className="read-more" href="causes-details.html">Read More</a>
                  </div>
                </div>
              </div>
              <div>
                <div className="single-campaing">
                  <div className="campaing-img">
                    <img src="assets/img/campaing/1.jpg" alt="" />
                  </div>
                  <div className="campaing-text">
                    <div className="progress pink">
                      <div className="progress-bar">

                      </div>
                    </div>
                    <ul>
                      <li><span>Raised:</span> $5000.00</li>
                      <li className="left-site"><span>Goal:</span> $8000.00</li>
                    </ul>
                    <h3>Help Us To Send Food</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur scinelit, sed do eiusmod tempor incididunt</p>
                    <a className="read-more" href="causes-details.html">Read More</a>
                  </div>
                </div>
              </div>
              <div>
                <div className="single-campaing">
                  <div className="campaing-img">
                    <img src="assets/img/campaing/1.jpg" alt="" />
                  </div>
                  <div className="campaing-text">
                    <div className="progress pink">
                      <div className="progress-bar">

                      </div>
                    </div>
                    <ul>
                      <li><span>Raised:</span> $5000.00</li>
                      <li className="left-site"><span>Goal:</span> $8000.00</li>
                    </ul>
                    <h3>Help Us To Send Food</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur scinelit, sed do eiusmod tempor incididunt</p>
                    <a className="read-more" href="causes-details.html">Read More</a>
                  </div>
                </div>
              </div>
              <div>
                <div className="single-campaing">
                  <div className="campaing-img">
                    <img src="assets/img/campaing/1.jpg" alt="" />
                  </div>
                  <div className="campaing-text">
                    <div className="progress pink">
                      <div className="progress-bar">

                      </div>
                    </div>
                    <ul>
                      <li><span>Raised:</span> $5000.00</li>
                      <li className="left-site"><span>Goal:</span> $8000.00</li>
                    </ul>
                    <h3>Help Us To Send Food</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur scinelit, sed do eiusmod tempor incididunt</p>
                    <a className="read-more" href="causes-details.html">Read More</a>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className=" shape shape-1">
          <img src="assets/img/shape/1.png" alt="Shape" />
        </div>
      </section> */}
      {/* <section className="help-area">
        <div className="container">
          <div className="help-title">
            <h2>Some Good Causes</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.</p>
            <a className="default-btn" href="donate.html">Donate Now</a>
            <a className="default-btn join" href="#">Join Now</a>
          </div>
        </div>
      </section> */}
      {/* <section className="get-started-today-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="get-started-img">
                <img src="assets/img/get-started/get-started.png" alt="Donation" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="get-started-title">
                <span>Get Started Today</span>
                <h2>Our Fundraise Plans</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </div>
              <div className="get-started-list">
                <img src="assets/img/get-started/1.png" alt="Started" />
                <h3>Education For Children</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
              </div>
              <div className="get-started-list">
                <img src="assets/img/get-started/2.png" alt="Started" />
                <h3>Home For Homeless People</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
              </div>
              <div className="get-started-list">
                <img src="assets/img/get-started/3.png" alt="Started" />
                <h3>Free Medical Services</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
              </div>
              <a className="default-btn" href="#">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="our-event-area">
        <div className="container">
          <div className="section-title">
            <span>Upcoming Events</span>
            <h2>Our Event</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.</p>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="single-event">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-xl-6 pr-0">
                    <div className="event-img">
                      <img src="assets/img/event/1.jpg" alt="Event" />
                      <div className="shape shape-11">
                        <img src="assets/img/shape/11.jpg" alt="Shape" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-6 pl-0">
                    <div className="event-text">
                      <h3>Donation is hope</h3>
                      <p>Lorem ipsum dolor sit amet, elip consectetur adipiscing sed.</p>
                      <ul>
                        <li>
                          <i className="flaticon-maps-and-flags"></i>
                          Newyork city
                        </li>
                        <li>
                          <i className="flaticon-clock-circular-outline"></i>
                          8.00 am-5.00 pm
                        </li>
                        <li>
                          <i className="flaticon-calendar"></i>
                          5 November 2019
                        </li>
                      </ul>
                      <a className="read-more" href="blog-details.html">
                        Read More
                      </a>
                      <span>01</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="single-event">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-xl-6 pr-0">
                    <div className="event-img">
                      <img src="assets/img/event/2.jpg" alt="Event" />
                      <div className="shape shape-11">
                        <img src="assets/img/shape/11.jpg" alt="Shape" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-6 pl-0">
                    <div className="event-text">
                      <h3>Donation is hope</h3>
                      <p>Lorem ipsum dolor sit amet, elip consectetur adipiscing sed.</p>
                      <ul>
                        <li>
                          <i className="flaticon-maps-and-flags"></i>
                          Newyork city
                        </li>
                        <li>
                          <i className="flaticon-clock-circular-outline"></i>
                          8.00 am-5.00 pm
                        </li>
                        <li>
                          <i className="flaticon-calendar"></i>
                          5 jun 2019
                        </li>
                      </ul>
                      <a className="read-more" href="blog-details.html">
                        Read More
                      </a>
                      <span>02</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="single-event">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-xl-6 pr-0">
                    <div className="event-img">
                      <img src="assets/img/event/3.jpg" alt="Event" />
                      <div className="shape shape-11">
                        <img src="assets/img/shape/11.jpg" alt="Shape" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-6 pl-0">
                    <div className="event-text">
                      <h3>A hand for children</h3>
                      <p>Lorem ipsum dolor sit amet, elip consectetur adipiscing sed.</p>
                      <ul>
                        <li>
                          <i className="flaticon-maps-and-flags"></i>
                          Newyork city
                        </li>
                        <li>
                          <i className="flaticon-clock-circular-outline"></i>
                          8.00 am-5.00 pm
                        </li>
                        <li>
                          <i className="flaticon-calendar"></i>
                          5 July 2019
                        </li>
                      </ul>
                      <a className="read-more" href="blog-details.html">
                        Read More
                      </a>
                      <span>03</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="single-event">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-xl-6 pr-0">
                    <div className="event-img">
                      <img src="assets/img/event/4.jpg" alt="Event" />
                      <div className="shape shape-11">
                        <img src="assets/img/shape/11.jpg" alt="Shape" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-6 pl-0">
                    <div className="event-text">
                      <h3>Make Donation</h3>
                      <p>Lorem ipsum dolor sit amet, elip consectetur adipiscing sed.</p>
                      <ul>
                        <li>
                          <i className="flaticon-maps-and-flags"></i>
                          Newyork city
                        </li>
                        <li>
                          <i className="flaticon-clock-circular-outline"></i>
                          8.00 am-5.00 pm
                        </li>
                        <li>
                          <i className="flaticon-calendar"></i>
                          5 November 2019
                        </li>
                      </ul>
                      <a className="read-more" href="blog-details.html">
                        Read More
                      </a>
                      <span>04</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="organaization-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="organaization-text">
                <h2>Small Growing charity organaization wants to raise money</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis  suspendisse  gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simple dummy text of the printing and typesetting industry.</p>
                <a className="default-btn" href="donate.html">
                  How To Donate
                </a>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="organaization">
                <img src="assets/img/organaization.png" alt="Organaization" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonial-area">
        <div className="container">
          <div className="section-title">
            <span>Testimonial</span>
            <h2>Their Thoughts About Our Work</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
              magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.</p>
          </div>
          <div className="row">
            <div className="testimonial-wrap">
              <Slider {...settingsThoughts}>
                <div className="single-testimonial">
                  <img src="assets/img/testimonial/1.jpg" alt="Testimonial" />
                  <h3>James Thomas</h3>
                  <i className="flaticon-left-quote"></i>
                  <p>Lorem ipsum dolor sit amet, consectet adipiscing sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua. Quis ipsum </p>
                </div>
                <div className="single-testimonial">
                  <img src="assets/img/testimonial/2.jpg" alt="Testimonial" />
                  <h3>James Thomas</h3>
                  <i className="flaticon-left-quote"></i>
                  <p>Lorem ipsum dolor sit amet, consectet adipiscing sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua. Quis ipsum </p>
                </div>
                <div className="single-testimonial">
                  <img src="assets/img/testimonial/3.jpg" alt="Testimonial" />
                  <h3>James Thomas</h3>
                  <i className="flaticon-left-quote"></i>
                  <p>Lorem ipsum dolor sit amet, consectet adipiscing sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua. Quis ipsum </p>
                </div>
                <div className="single-testimonial">
                  <img src="assets/img/testimonial/3.jpg" alt="Testimonial" />
                  <h3>James Thomas</h3>
                  <i className="flaticon-left-quote"></i>
                  <p>Lorem ipsum dolor sit amet, consectet adipiscing sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua. Quis ipsum </p>
                </div>
                <div className="single-testimonial">
                  <img src="assets/img/testimonial/3.jpg" alt="Testimonial" />
                  <h3>James Thomas</h3>
                  <i className="flaticon-left-quote"></i>
                  <p>Lorem ipsum dolor sit amet, consectet adipiscing sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua. Quis ipsum </p>
                </div>
                <div className="single-testimonial">
                  <img src="assets/img/testimonial/3.jpg" alt="Testimonial" />
                  <h3>James Thomas</h3>
                  <i className="flaticon-left-quote"></i>
                  <p>Lorem ipsum dolor sit amet, consectet adipiscing sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua. Quis ipsum </p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        <div className="shape shape-8">
          <img src="assets/img/shape/8.png" alt="Shape" />
        </div>
      </section> */}
      <section className="latest-news-area">
        <div className="container">
          <div className="section-title">
            {/* <span>Latest News</span> */}
            <h2>Latest News</h2>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.</p> */}
          </div>
          <div className="row">
            {isLoaded ?
              getArticlesList.map((item, idx) => {
                return (
                  <div className="col-lg-4 col-md-6 col-12 news">
                    <Link to={`/story/${item.id}/${item.uri.split('/')[3]}`}>
                      <div className="single-news">
                        <div className="content">
                          <img src="https://chanzuckerberg.com/wp-content/uploads/2021/08/21-0823-CZI-HP-EOSS-Square.jpg" />
                          <h3>{item.category_title}</h3>
                          <p>{item.meta_desc}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              })
              : <div>Loading News</div>
            }
          </div>
        </div>
      </section>
      {/* <section className="partner-area pb-100">
        <div className="container">
          <div className="section-title">
            <span>Our Fundraising</span>
            <h2>Charity Organaization Worked In The Fundraising </h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.</p>
          </div>
          <div className="partner-wrap">
            <Slider {...settingsClients}>
              <div className="single-logo">
                <img src="assets/img/partner-logo/1.png" alt="Partner" />
              </div>
              <div className="single-logo">
                <img src="assets/img/partner-logo/2.png" alt="Partner" />
              </div>
              <div className="single-logo">
                <img src="assets/img/partner-logo/3.png" alt="Partner" />
              </div>
              <div className="single-logo">
                <img src="assets/img/partner-logo/4.png" alt="Partner" />
              </div>
              <div className="single-logo">
                <img src="assets/img/partner-logo/5.png" alt="Partner" />
              </div>
              <div className="single-logo">
                <img src="assets/img/partner-logo/5.png" alt="Partner" />
              </div>
            </Slider>
          </div>
        </div>
      </section> */}
    </div >
  );
}

export default Home;
