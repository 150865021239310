import { useForm } from "react-hook-form";

function Donate() {

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = data => console.log(data);

  return (
    <div>
      <div className="page-title-area item-bg-8">
        <div className="container">
          <div className="page-title-content">
            <h2>Donate Now</h2>
            <ul>
              <li>
                <a href="index.html">
                  Home
                  <i className="fa fa-chevron-right"></i>
                </a>
              </li>
              <li>Donate Now</li>
            </ul>
          </div>
        </div>
      </div>
      <section className="donate-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="donate-img"></div>
            </div>
            <div className="col-lg-6">
              <div className="donates-wraps">
                <div className="payment-method">
                  <h3>Select Payment Method</h3>
                  <p>
                    <input type="radio" id="paypal" name="radio-group" />
                    <label for="paypal">PayPal</label>
                  </p>
                  <p>
                    <input type="radio" id="credit" name="radio-group" />
                    <label for="credit">Credit Card</label>
                  </p>
                  <p>
                    <input type="radio" id="debit" name="radio-group" />
                    <label for="debit">Debit Card</label>
                  </p>
                  <p>
                    <input type="radio" id="others" name="radio-group" />
                    <label for="others">others</label>
                  </p>
                </div>
                <div className="contact-form">
                  <form id="contactForm" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input type="text" name="name" id="first-name" className="form-control" required=""   {...register("fName")} placeholder="First Name" />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <input type="text" name="name" id="last-name" className="form-control" required="" {...register("lName")} placeholder="Last Name" />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <input type="email" name="email" id="email" className="form-control" required="" {...register("email")} placeholder="Email" />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <input type="text" name="phone_number" id="phone_number" required="" {...register("phone")} className="form-control" placeholder="Phone" />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <input type="text" name="msg_subject" id="msg_subject" className="form-control" required="" {...register("amount")} placeholder="$100.00" />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <button type="submit" className="default-btn disabled">Donate Now</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Donate;