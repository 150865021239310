import './App.scss';
import Navbar from './components/common/navbar';
import Footer from './components/common/footer';

import Home from './components/home/Home';
import About from './components/about/About';
import Blog from './components/blog/Blog';
import Education from './components/education/Education';
import Ourwork from './components/ourwork/Ourwork';
import Joinus from './components/joinus/Joinus';
import Contact from './components/contact/Contact';
import Donate from './components/donate/Donate';
import Story from './components/story/Story';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import { Route, Switch } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

function App() {
  const [topIcon, showTopIcon] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
  }, []);


  const handleScroll = () => {
    let position = window.pageYOffset;
    position >= 300 ? showTopIcon(true) : showTopIcon(false);
  };

  const scrollUp = () => {
    window.scrollTo(0, 0)
  }

  return (
    <div className="app-main">
      <Navbar topIcon={topIcon} />

      <main>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/about" component={About} exact />
          <Route path="/blog" component={Blog} exact />
          <Route path="/contact" component={Contact} exact />
          <Route path="/ourwork" component={Ourwork} exact />
          <Route path="/education" component={Education} exact />
          <Route path="/joinus" component={Joinus} exact />
          <Route path="/donate" component={Donate} exact />
          <Route path="/story/:id/:title" component={Story} exact />
        </Switch>
      </main>

      <Footer />
      <div className={`go-top ${topIcon ? 'active' : ''}`} onClick={() => scrollUp()}>
        <FontAwesomeIcon icon={faAngleDoubleUp} />
      </div>
    </div>
  );
}

export default App;
