function Blog() {
  return (
    <div>
      <div className="page-title-area item-bg-3">
        <div className="container">
          <div className="page-title-content">
            <h2>Blog Grid</h2>
            <ul>
              <li>
                <a href="index.html">
                  Home
                  <i className="fa fa-chevron-right"></i>
                </a>
              </li>
              <li>Blog Grid</li>
            </ul>
          </div>
        </div>
      </div>
      <section className="blog-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-news">
                <div className="news-img">
                  <a href="blog-details.html">
                    <img src="assets/img/news/1.jpg" alt="News"/>
                  </a>
                  <div className="date">
                    <span>1 November</span>
                  </div>
                </div>
                <div className="news-text">
                  <ul>
                    <li>
                      <i className="flaticon-man-user"></i>
                      By
                      <a href="#">Admin</a>
                    </li>
                    <li>
                      <i className="flaticon-speech-bubbles-comment-option"></i>
                      <a href="#">3 Comments</a>
                    </li>
                  </ul>
                  <h3>
                    <a href="blog-details.html">Highlight some of the fundraising</a>
                  </h3>
                  <p>Lorem ipsum dolor sit amet, consectetur eiussi adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                  <a className="read-more" href="blog-details.html">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-news">
                <div className="news-img">
                  <a href="blog-details.html">
                    <img src="assets/img/news/2.jpg" alt="News"/>
                  </a>
                  <div className="date">
                    <span>2 November</span>
                  </div>
                </div>
                <div className="news-text">
                  <ul>
                    <li>
                      <i className="flaticon-man-user"></i>
                      By
                      <a href="#">Admin</a>
                    </li>
                    <li>
                      <i className="flaticon-speech-bubbles-comment-option"></i>
                      <a href="#">3 Comments</a>
                    </li>
                  </ul>
                  <h3>
                    <a href="blog-details.html">
                      A place start a new life with peace
                    </a>
                  </h3>
                  <p>Lorem ipsum dolor sit amet, consectetur eiussi adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                  <a className="read-more" href="blog-details.html">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-news">
                <div className="news-img">
                  <a href="blog-details.html">
                    <img src="assets/img/news/3.jpg" alt="News"/>
                  </a>
                  <div className="date">
                    <span>3 November</span>
                  </div>
                </div>
                <div className="news-text">
                  <ul>
                    <li>
                      <i className="flaticon-man-user"></i>
                      By
                      <a href="#">Admin</a>
                    </li>
                    <li>
                      <i className="flaticon-speech-bubbles-comment-option"></i>
                      <a href="#">3 Comments</a>
                    </li>
                  </ul>
                  <h3>
                    <a href="blog-details.html">Build school for poor children</a>
                  </h3>
                  <p>Lorem ipsum dolor sit amet, consectetur eiussi adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                  <a className="read-more" href="blog-details.html">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-news">
                <div className="news-img">
                  <a href="blog-details.html">
                    <img src="assets/img/news/4.jpg" alt="News"/>
                  </a>
                  <div className="date">
                    <span>1 Jun</span>
                  </div>
                </div>
                <div className="news-text">
                  <ul>
                    <li>
                      <i className="flaticon-man-user"></i>
                      By
                      <a href="#">Admin</a>
                    </li>
                    <li>
                      <i className="flaticon-speech-bubbles-comment-option"></i>
                      <a href="#">3 Comments</a>
                    </li>
                  </ul>
                  <h3>
                    <a href="blog-details.html">
                      Support for Children
                    </a>
                  </h3>
                  <p>Lorem ipsum dolor sit amet, consectetur eiussi adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                  <a className="read-more" href="blog-details.html">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-news">
                <div className="news-img">
                  <a href="blog-details.html">
                    <img src="assets/img/news/5.jpg" alt="News"/>
                  </a>
                  <div className="date">
                    <span>2 Julay</span>
                  </div>
                </div>
                <div className="news-text">
                  <ul>
                    <li>
                      <i className="flaticon-man-user"></i>
                      By
                      <a href="#">Admin</a>
                    </li>
                    <li>
                      <i className="flaticon-speech-bubbles-comment-option"></i>
                      <a href="#">3 Comments</a>
                    </li>
                  </ul>
                  <h3>
                    <a href="blog-details.html">
                      Capetown orphanage
                    </a>
                  </h3>
                  <p>Lorem ipsum dolor sit amet, consectetur eiussi adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                  <a className="read-more" href="blog-details.html">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-news">
                <div className="news-img">
                  <a href="blog-details.html">
                    <img src="assets/img/news/6.jpg" alt="News"/>
                  </a>
                  <div className="date">
                    <span>3 Appril</span>
                  </div>
                </div>
                <div className="news-text">
                  <ul>
                    <li>
                      <i className="flaticon-man-user"></i>
                      By
                      <a href="#">Admin</a>
                    </li>
                    <li>
                      <i className="flaticon-speech-bubbles-comment-option"></i>
                      <a href="#">3 Comments</a>
                    </li>
                  </ul>
                  <h3>
                    <a href="blog-details.html">Home For Homeless</a>
                  </h3>
                  <p>Lorem ipsum dolor sit amet, consectetur eiussi adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                  <a className="read-more" href="blog-details.html">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-12">
            <div className="pagenavigation-area">
              <nav aria-label="Page navigation example text-center">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link page-links" href="#">
                      <i className="fa fa-angle-double-left"></i>
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="#">1</a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">2</a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">3</a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fa fa-angle-double-right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  )
}

export default Blog;